import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, colors } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  gridItemBorder: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${colors.grey[200]}`,
    }
  }
}));

const Support = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} className={classes.gridItemBorder}>
          <SectionHeader
            title={<span>No <span style={{ color: 'rgb(255,100,83)' }}> automatic renewal</span></span>}
            titleVariant="h4"
            subtitle="Only pay for what you choose without having to worry about cancelling for next period."
            subtitleVariant="body1"
            subtitleColor="textSecondary"
            disableGutter
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionHeader
            title={<span><span style={{ color: 'rgb(255,100,83)' }}>Full support</span> included</span>}
            titleVariant="h4"
            subtitle="Full support is included with all paid licenses. Having a question? Need help? We got you!"
            subtitleVariant="body1"
            subtitleColor="textSecondary"
            disableGutter
          />
        </Grid>
      </Grid>
    </div>
  );
};

Support.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Support;
